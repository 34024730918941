.title {
  text-align: left;
  margin: 1rem 0;
  border-bottom: 1px solid grey;
}

.area {
  background: #212121;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
